import { inject, Injectable, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AppStorageService } from '@shared/services/app-storage.service';
import { map } from 'rxjs';

const STORAGE_NAME: string = 'lb_language';

@Injectable({
    providedIn: 'root',
})
export class AppLanguageService {
    private appStorage: AppStorageService = inject(AppStorageService);
    private translate: TranslateService = inject(TranslateService);

    languages: Signal<string[]> = signal(['en', 'de']);

    current: Signal<string> = toSignal(this.translate.onLangChange.pipe(map((lang: LangChangeEvent) => lang.lang)), {
        initialValue: 'de',
    });

    fnInit() {
        this.translate.setDefaultLang('de');
        const language: string | undefined = this.appStorage.getItem(STORAGE_NAME);
        if (language) {
            this.translate.use(language);
        } /*else {
			Device.getLanguageCode().then((lang: GetLanguageCodeResult) => {
				this.translate.use(lang.value);
			});
		}*/
    }

    set(lang: string) {
        this.translate.use(lang);
        this.appStorage.setItem(STORAGE_NAME, lang);
    }
}
